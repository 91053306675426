import React from "react";
import "./fakeSubmit.css";
import { useSelector } from "react-redux";
import WinnersDisplay from "./WinnersDisplay";

export default function FakeSubmit() {
  const games = useSelector((state) => state.predictionsReducer);

  return (
    <div className="fake-submit__container">
      <div className="fake-submit__text-wrapper">
        <h1>Submission Added Successfuly!</h1>
        <p>Your Submission:</p>
        {games.map((game) => (
          <WinnersDisplay
            key={game.id}
            winner={game.selectedWinner}
            spread={game.selectedSpread}
          />
        ))}
      </div>
    </div>
  );
}

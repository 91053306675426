import React from "react";
import "./scoreSpread.css";
import { useSelector, useDispatch } from "react-redux";

export default function SingleScoreSpread({ spread, gameId }) {
  const games = useSelector((state) => state.predictionsReducer);
  const currentGame = games.find((game) => game.id === gameId);

  const isSelectedSpred = currentGame.selectedSpread;

  if (isSelectedSpred === spread) {
    return (
      <div className="score-spread__selected-spread">
        <div style={{ position: "relative" }}>
          {/* <div className="score-spread__costum-border-top"></div> */}

          <div
            style={{
              fontFamily: "Landasans",
              fontSize: 60,
              color: "#A34A25",
            }}
          >
            {spread}
          </div>
          {/* <div className="score-spread__costum-border-buttom"></div> */}
          {/* <p className="score-spread__points-text">POINTS</p> */}
        </div>
      </div>
    );
  } else {
    return (
      <div
        // onClick={() => dispatch(selectSpread(gameId, spread))}
        style={{
          fontFamily: "Landasans",
          fontSize: 60,
          color: "#CEC8CD",
        }}
      >
        <div className="score-spread__not-selected-spread">{spread}</div>
      </div>
    );
  }
}

import { gamesData } from "../dummy";

export function selectWinningTeam(teamName, gameId) {
  return {
    type: "SELECT_WINNING_TEAM",
    payload: { teamName: teamName, gameId: gameId },
  };
}

export function selectSpread(gameId, spread) {
  return {
    type: "SELECT_SPREAD",
    payload: { gameId: gameId, spread: spread },
  };
}

export default function predictionsReducer(state = gamesData, action) {
  switch (action.type) {
    case "SELECT_WINNING_TEAM": {
      const { teamName } = action.payload;
      const { gameId } = action.payload;
      const selectedGame = state.findIndex((game) => game.id === gameId);
      //not mutating because we create new array using "map"
      const updatedGame = state.map((game, index) =>
        index === selectedGame ? { ...game, selectedWinner: teamName } : game
      );
      return [...state], updatedGame;
    }

    case "SELECT_SPREAD": {
      const { gameId } = action.payload;
      const { spread } = action.payload;
      const selectedGame = state.findIndex((game) => game.id === gameId);
      const updatedGame = state.map((game, index) =>
        index === selectedGame ? { ...game, selectedSpread: spread } : game
      );
      return [...state], updatedGame;
    }

    default:
      return state;
  }
}

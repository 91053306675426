export const gamesData = [
  {
    id: 1,
    homeTeam: "Nets",
    awayTeam: "Bucks",
    round: 1,
    date: "Mon, Sept 24nd",
    hour: "11:00",
    location: "Rupp Arena",
    selectedWinner: null,
    selectedSpread: null,
  },
  {
    id: 2,
    homeTeam: "GS Warriors",
    awayTeam: "LA Lakers",
    round: 1,
    date: "Mon, Sept 24nd",
    hour: "11:00",
    location: "Rupp Arena",
    selectedWinner: null,
    selectedSpread: null,
  },
  {
    id: 3,
    homeTeam: "Pacers",
    awayTeam: "Hornets",
    round: 1,
    date: "Mon, Sept 24nd",
    hour: "11:00",
    location: "Rupp Arena",
    selectedWinner: null,
    selectedSpread: null,
  },
  {
    id: 4,
    homeTeam: "Bulls",
    awayTeam: "Pistons",
    round: 1,
    date: "Mon, Sept 24nd",
    hour: "11:00",
    location: "Rupp Arena",
    selectedWinner: null,
    selectedSpread: null,
  },
  {
    id: 5,
    homeTeam: "Celtics",
    awayTeam: "NY Knicks",
    round: 1,
    date: "Mon, Sept 24nd",
    hour: "11:00",
    location: "Rupp Arena",
    selectedWinner: null,
    selectedSpread: null,
  },
  {
    id: 6,
    homeTeam: "Wizards",
    awayTeam: "Raptors",
    round: 1,
    date: "Mon, Sept 24nd",
    hour: "11:00",
    location: "Rupp Arena",
    selectedWinner: null,
    selectedSpread: null,
  },
];

import React from "react";
import "./logoHeader.css";
import hookApps from "../assets/hookApps.png";

export default function LogoHeader() {
  return (
    <div className="logo-header__container">
      <div className="logo-header__img">
        <img className="logo-header__img-tag" src={hookApps} alt="logo" />
      </div>
      <div className="logo-header__game-name">
        <h1 className="logo-header__hit">Hit 6</h1>
      </div>
      <div className="logo-header__empty"></div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./singleTeam.css";
import { useSelector, useDispatch } from "react-redux";
import { selectWinningTeam } from "../redux/predictionReducer";
import Bucks from "../assets/logos/Bucks.svg";
import Bulls from "../assets/logos/Bulls.svg";
import Celtics from "../assets/logos/Celtics.svg";
import GoldenState from "../assets/logos/Golden-State.svg";
import Hornets from "../assets/logos/Hornets.svg";
import LAlakers from "../assets/logos/LA-Lakers.svg";
import NYKnicks from "../assets/logos/NY-Knicks.svg";
import Pacers from "../assets/logos/Pacers.svg";
import Pistons from "../assets/logos/Pistons.svg";
import Raptors from "../assets/logos/Raptors.svg";
import Wizards from "../assets/logos/Wizards.svg";
import Nets from "../assets/logos/Nets.svg";

export default function SingleTeam({ isHomeTeam, teamName, jersey, gameId }) {
  const games = useSelector((state) => state.predictionsReducer);
  const dispatch = useDispatch();
  const [backgroundColor, setBackgroundColor] = useState({
    backgroundColor: "white",
  });

  const currentGame = games.find((game) => game.id === gameId);

  useEffect(() => {
    if (teamName === currentGame.selectedWinner) {
      setBackgroundColor({ backgroundColor: "#ECE5E1" });
    } else {
      setBackgroundColor({ backgroundColor: "white" });
    }
  }, [teamName, currentGame.selectedWinner]);

  let borderRadius = isHomeTeam
    ? { borderRadius: "10px 0px 0px 10px" }
    : { borderRadius: "0px 10px 10px 0px" };
  let TeamLogo;
  switch (teamName) {
    case "Nets":
      TeamLogo = Nets;
      break;
    case "Bucks":
      TeamLogo = Bucks;
      break;
    case "GS Warriors":
      TeamLogo = GoldenState;
      break;
    case "LA Lakers":
      TeamLogo = LAlakers;
      break;
    case "Pacers":
      TeamLogo = Pacers;
      break;
    case "Hornets":
      TeamLogo = Hornets;
      break;
    case "Bulls":
      TeamLogo = Bulls;
      break;
    case "Pistons":
      TeamLogo = Pistons;
      break;
    case "Celtics":
      TeamLogo = Celtics;
      break;
    case "NY Knicks":
      TeamLogo = NYKnicks;
      break;
    case "Raptors":
      TeamLogo = Raptors;
      break;
    case "Wizards":
      TeamLogo = Wizards;
      break;
  }

  return (
    <div
      className="single-team__container"
      style={{ ...borderRadius, ...backgroundColor }}
      onClick={() => dispatch(selectWinningTeam(teamName, gameId))}
    >
      <div className="single-team__name-shirt">
        <p>{teamName}</p>
        <img
          style={{ width: 100 }}
          className="single-team__image"
          src={TeamLogo}
          alt="jersey"
        />
        {teamName === currentGame.selectedWinner && currentGame.selectedSpread && (
          <div className="single-team__spread-sign">
            <div className="pin-top"></div>
            <p className="single-team__spread-sign-text">
              {currentGame.selectedSpread}
            </p>
            <div className="pin-bottom"></div>
          </div>
        )}
      </div>
    </div>
  );
}

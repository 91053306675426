import React, { useState } from "react";
import "./gameScreen.css";
import DateTime from "./DateTime";
import Teams from "./Teams";
import ScoreSpread from "./ScoreSpread";
import Button from "./Button";
import Carousel from "react-material-ui-carousel";
import SingleGame from "./SingleGame";
import { useSelector } from "react-redux";
import PrevNextBtn from "./PrevNextBtn";

export default function GameScreen({ setGoToSubmitedScreen }) {
  const games = useSelector((state) => state).predictionsReducer;

  const areAllGemesPredicted = games.find((game, index) =>
    game.selectedWinner === null || game.selectedSpread === null
      ? game.id
      : null
  );

  const submitHandler = () => {
    setGoToSubmitedScreen(true);
  };
  const [currentCaruselIndex, setCurrentCaruselIndex] = useState(0);
  return (
    <div
      style={{ position: "relative", top: "-7px" }}
      className="game-screen__container"
    >
      <Carousel
        // index={index}
        changeOnFirstRender={true}
        onChange={(index, active) => setCurrentCaruselIndex(index)}
        navButtonsAlwaysVisible={true}
        animation="slide"
        swipe={false}
        autoPlay={false}
        NextIcon={"next"}
        navButtonsWrapperProps={{
          // Move the buttons to the bottom. Unsetting top here to override default style.
          style: {
            height: "1px",
            top: "480px",
            background: "transparent",
            left: "50%",
            transform: "translate(-50%)",
            borderRadius: 50,
          },
        }}
        indicatorContainerProps={{
          style: {
            marginTop: "-25px", // 5
            paddingBottom: "0.35em",
          },
        }}
        // IndicatorIcon={
        //   <div
        //     style={{
        //       fontSize: 10,
        //     }}
        //   ></div>
        // }
        indicatorIconButtonProps={{
          style: {
            // height: 10,
            // width: 15,
            color: "#CECECE",
            padding: "10px 5px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#0C1A34", // 2
          },
        }}
        NavButton={({ onClick, className, style, next, prev }) => {
          // Other logic

          return (
            <PrevNextBtn
              isThereActiveGame={areAllGemesPredicted}
              onClick={onClick}
              className={className}
              style={style}
              next={next}
              prev={prev}
              currentCaruselIndex={currentCaruselIndex}
            ></PrevNextBtn>
          );
        }}
      >
        {games.map((game) => {
          return (
            <SingleGame key={game.id}>
              <div className="">
                <DateTime
                  date={game.date}
                  hour={game.hour}
                  location={game.location}
                />
                <Teams
                  selectedWinner={game.selectedWinner}
                  gameId={game.id}
                  homeTeam={game.homeTeam}
                  awayTeam={game.awayTeam}
                />
                <ScoreSpread gameId={game.id} />
                {areAllGemesPredicted ? (
                  <div style={{ visibility: "hidden", height: "64px" }}></div>
                ) : (
                  <Button buttonText="SUBMIT!" submitHandler={submitHandler} />
                )}
              </div>
            </SingleGame>
          );
        })}
      </Carousel>
      <div
        style={{
          color: "#2B5A64",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%)",
          marginTop: "-0.5em",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        {currentCaruselIndex + 1} out of 6
      </div>
    </div>
  );
}

import React from "react";
import "./dateTime.css";
import calendar from "../assets/calandar.png";
import clock from "../assets/clock.png";
import stadium from "../assets/stadium.png";

export default function DateTime({ date, hour, location }) {
  return (
    <div className="date-time__container">
      <img alt="calendar" src={calendar} className="date-time__img" />
      <p className="date-time__text">{date} |&nbsp;</p>
      <img className="date-time__img" alt="clock" src={clock} />
      <p className="date-time__text"> {hour} |&nbsp;</p>
      <img className="date-time__img" alt="stadium" src={stadium} />
      <p className="date-time__text"> {location}</p>
    </div>
  );
}

import React from "react";
import "./teams.css";
import SingleTeam from "./SingleTeam";
import Court from "./Court";
import jersey1 from "../assets/jersey1.png";
import jersey2 from "../assets/jersey2.png";
import shtrudel from "../assets/shtrudel.png";

export default function Teams({ selectedWinner, gameId, homeTeam, awayTeam }) {
  return (
    <div style={{ position: "relative" }}>
      <div className="teams__container">
        <div className="teams__a">
          <img style={{ width: 24 }} src={shtrudel} alt="" />
        </div>
        <SingleTeam
          isHomeTeam={true}
          teamName={homeTeam}
          jersey={jersey1}
          gameId={gameId}
        />
        <SingleTeam
          isHomeTeam={false}
          teamName={awayTeam}
          jersey={jersey2}
          gameId={gameId}
        />
      </div>
      <Court
        selectedWinner={selectedWinner}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
      {selectedWinner ? (
        <p style={{ textAlign: "center" }} className="score-spread__to-win">
          {selectedWinner.toUpperCase()} TO WIN BY
        </p>
      ) : (
        <p className="score-spread__to-win" style={{ visibility: "hidden" }}>
          ""
        </p>
      )}
    </div>
  );
}

import { createStore, combineReducers } from "redux";
import predictionsReducer from "./predictionReducer";

const store = createStore(
  combineReducers({
    predictionsReducer,
  })
);
store.subscribe(() => console.log(store.getState()));
export default store;

import React from "react";
import basketballCourt from "../assets/basketball-court.png";
import "./court.css";
import selectedOpacity from "../assets/selectedOpacity.png";

export default function Court({ selectedWinner, homeTeam, awayTeam }) {
  let opacityColor;
  if (selectedWinner === homeTeam) {
    opacityColor = (
      <img className="court__opacity" src={selectedOpacity} alt="opacity" />
    );
  }
  if (selectedWinner === awayTeam) {
    opacityColor = (
      <img
        style={{
          transform: "rotate(180deg)",
          transform: "rotatey(180deg)",
          left: "50%",
        }}
        className="court__opacity"
        src={selectedOpacity}
        alt="opacity"
      />
    );
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="court__container">
        {opacityColor}
        <img style={{ width: 360 }} src={basketballCourt} alt="court" />
      </div>
    </div>
  );
}

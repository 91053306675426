import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import navArrow from "../assets/navArrow.png";

export default function PrevNextBtn({
  children,
  onClick,
  className,
  style,
  next,
  prev,
  isThereActiveGame,
  currentCaruselIndex,
}) {
  const games = useSelector((state) => state.predictionsReducer);
  const getCurrentGame = games.filter((game, index) => {
    return index === currentCaruselIndex;
  });

  const [isActiveNextBtn, setIsActiveNextBtn] = useState(false);

  useEffect(() => {
    if (getCurrentGame[0].selectedWinner && getCurrentGame[0].selectedSpread) {
      setIsActiveNextBtn(true);
    } else {
      setIsActiveNextBtn(false);
    }
  }, [
    getCurrentGame[0].selectedWinner,
    getCurrentGame[0].selectedSpread,
    games,
  ]);
  if (next === true) {
    if (!isThereActiveGame) {
      return (
        <div style={{ ...forwardBtn, marginTop: 47 }} onClick={onClick}>
          <img
            style={{ transform: "rotate(180deg)" }}
            src={navArrow}
            alt="nav arrow"
          />
        </div>
      );
    } else if (isActiveNextBtn) {
      return (
        <div>
          <div style={next ? nextStyle : { display: "none" }} onClick={onClick}>
            <div onClick={() => setIsActiveNextBtn(false)}>NEXT</div>
          </div>
          <div style={forwardBtn} onClick={onClick}>
            <img
              style={{ transform: "rotate(180deg)" }}
              src={navArrow}
              alt="nav arrow"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={next ? disabledBtn : backBtm}>NEXT</div>
          <div style={forwardBtn} onClick={onClick}>
            <img
              style={{ transform: "rotate(180deg)" }}
              src={navArrow}
              alt="nav arrow"
            />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div onClick={onClick} style={next ? disabledBtn : backBtm}>
        <img src={navArrow} alt="nav arrow" />
      </div>
    );
  }
}

const nextStyle = {
  fontFamily: "Roboto Condensed, sans-serif",
  width: 175,
  backgroundColor: "#2b5a64",
  margin: "5px auto",
  color: "white",
  padding: "10px 15px",
  boxSizing: "border-box",
  textAlign: "center",
  borderRadius: 50,
};

const disabledBtn = {
  ...nextStyle,
  backgroundColor: "#CBCBCB",
};

const backBtm = {
  marginTop: "3em",
  marginRight: "80vw",
  background: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  height: 32,
  width: 32,
  borderRadius: 50,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const forwardBtn = {
  background: "white",
  marginTop: "-0.5em",
  width: 32,
  height: 32,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "60vw",
  borderRadius: 50,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
};

import "./App.css";
import React, { useState } from "react";
import LogoHeader from "./components/LogoHeader";
import SubHeader from "./components/SubHeader";
import GameScreen from "./components/GameScreen";
import FakeSubmit from "./components/FakeSubmit";

function App() {
  const [goToSubmitedScreen, setGoToSubmitedScreen] = useState(false);
  if (!goToSubmitedScreen) {
    return (
      <div className="App">
        <LogoHeader />
        <SubHeader />
        <GameScreen setGoToSubmitedScreen={setGoToSubmitedScreen} />
      </div>
    );
  } else {
    return (
      <div>
        <FakeSubmit />
      </div>
    );
  }
}

export default App;

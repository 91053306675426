import React, { useState, useEffect } from "react";
import "./scoreSpread.css";
import SingleScoreSpread from "./SingleScoreSpread";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { selectSpread } from "../redux/predictionReducer";

export default function ScoreSpread({ gameId }) {
  const [selectedSpread, setSelectedSpread] = useState("4-6");

  const dispatch = useDispatch();
  const changeSlide = (index, gameId) => {
    let spread = "1-3";
    switch (index) {
      case 5:
        spread = "1-3";
        return dispatch(selectSpread(gameId, spread));
      case 0:
        spread = "4-6";
        return dispatch(selectSpread(gameId, spread));
      case 1:
        spread = "7-9";
        return dispatch(selectSpread(gameId, spread));
      case 2:
        spread = "10-13";
        return dispatch(selectSpread(gameId, spread));
      case 3:
        spread = "14-17";
        return dispatch(selectSpread(gameId, spread));
      case 4:
        spread = "18-21";
        return dispatch(selectSpread(gameId, spread));
      default:
        return dispatch(selectSpread(1, "1-3"));
    }
  };

  useEffect(() => {
    changeSlide(5, gameId);
  }, []);

  const sliderSettings = {
    // className: "center",
    // centerMode: true,
    // infinite: false,
    // centerPadding: "60px",
    slidesToShow: 3,
    speed: 400,
    swipeToSlide: true,
    initialSlide: 5,
    // afterChange: (index) => setIndex(index),
    touchThreshold: 10,
    afterChange: (index) => changeSlide(index, gameId),
  };
  return (
    <div className="score-spread__container">
      <div className="score-spread__spreads">
        <div className="score-spread__costum-border-top"></div>

        <Slider {...sliderSettings}>
          <SingleScoreSpread
            setSelectedSpread={setSelectedSpread}
            selectedSpread={selectedSpread}
            spread="1-3"
            gameId={gameId}
          />
          <SingleScoreSpread
            setSelectedSpread={setSelectedSpread}
            selectedSpread={selectedSpread}
            spread="4-6"
            gameId={gameId}
          />
          <SingleScoreSpread
            setSelectedSpread={setSelectedSpread}
            selectedSpread={selectedSpread}
            spread="7-9"
            gameId={gameId}
          />
          <SingleScoreSpread
            setSelectedSpread={setSelectedSpread}
            selectedSpread={selectedSpread}
            spread="10-13"
            gameId={gameId}
          />
          <SingleScoreSpread
            setSelectedSpread={setSelectedSpread}
            selectedSpread={selectedSpread}
            spread="14-17"
            gameId={gameId}
          />
          <SingleScoreSpread
            setSelectedSpread={setSelectedSpread}
            selectedSpread={selectedSpread}
            spread="18-21"
            gameId={gameId}
          />
        </Slider>
      </div>
      <div className="score-spread__spreads">
        <div className="score-spread__costum-border-buttom"></div>
        <p className="score-spread__points-text">POINTS</p>
      </div>
    </div>
  );
}

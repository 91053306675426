import React from "react";

export default function WinnersDisplay({ winner, spread }) {
  return (
    <div>
      <p>
        <span style={{ fontWeight: "bold" }}>{winner}</span> to win by {spread}{" "}
        points
      </p>
    </div>
  );
}

import React from "react";
import "./subHeader.css";

export default function SubHeader() {
  return (
    <div className="sub-header__container">
      {/* add the round component later */}
      {/* <Round id={game.id} round={game.round} /> */}
      <h1 className="sub-header__round">Round 1</h1>
      <h2 className="sub-header__pick">Pick the 6 winners and the spread</h2>
    </div>
  );
}

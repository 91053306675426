import React from "react";
import "./button.css";

export default function Button({ buttonText, submitHandler }) {
  return (
    <div onClick={() => submitHandler()} className="button__container">
      <div className="button__button">{buttonText}</div>
    </div>
  );
}
